html,
body {
  font-family: "Inter";
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

tr {
  border-bottom: 1px solid #ddd;
}

th {
  text-align: left;
  font-weight: bolder;
}

th,
td {
  padding: 10px;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
}

#container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

main {
  flex-grow: 1;
  padding: 20px;
  max-width: 80ch;
}

footer {
  background-color: #03045e;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footer-text {
  text-align: end;
  color: #00f5d4;
}

footer h1 {
  text-align: start;
  color: #00f5d4;
}

.navbar {
  display: flex;
  justify-content: space-between;
  background-color: #03045e;
  padding: 10px;
}

.navbar a {
  font-size: 1.2em;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 800;
  /* width: 80px; */
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin-top: 16px;
  margin-bottom: 16px;
}

.menu-items {
  position: fixed;
  height: 100vh;
  top: 0px;
  right: 0px;
  transition: transform 0.5s ease-in-out;
  background-color: #00f5d4;
  width: 100%;
  padding-top: 100px;
  z-index: 90;
  min-height: -webkit-fill-available;
}

.navbar .menu-items li {
  margin-bottom: 1.2rem;
  font-size: 1.5rem;
  font-weight: 500;
  list-style: none;
  text-align: center;
}

.navbar img {
  z-index: 99;
}

.navbar a {
  color: #03045e;
}

#nav-container {
  display: flex;
  align-items: center;
}

a {
  color: #90e0ef;
}

a:hover {
  color: #00bbf9;
  text-decoration: underline;
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

.button {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.button:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

.loading-spinner {
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid lightgrey;
  border-radius: 50%;
  animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: lightgrey transparent transparent transparent;
}
.loading-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00bbf9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00bbf9;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.leaderboard-toggle-container {
  display: flex;
  justify-content: space-between;
}

th {
  font-weight: bolder;
}

td > a {
  color: #03045e;
}

.authButton {
  background: transparent;
  border: none !important;
  font-size: 0;
}

.authButton:hover {
  transform: translateY(-2px);
  cursor: pointer;
}
